@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-dashboard {
  .card-body {
    padding: 0;
  }

  .dashboard-alert {
    margin-top: 15px;
    margin-left: 1.5%;
    width: 97%;
  }

  #v2-dashboard-welcome {
    // imagem é configurada via js
    background: #15467c center right 110px no-repeat;
    height: 350px;
    margin: 0px;
    margin-bottom: 30px;

    h1 {
      display: block;
      max-width: 550px;
      padding: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > span:first-child {
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }

      span {
        color: white;
        > span {
          color: $clr-support-yellow;
        }
      }
    }
  }

  #v2-dashboard-group-blocks {
    padding: 20px;

    .v2-dashboard-group {
      position: relative;
      margin-bottom: 50px;

      .v2-dashboard-group-head {
        display: flex;

        .v2-dashboard-group-icon {
          max-width: 20px;
          max-height: 20px;
        }

        .v2-dashboard-group-title {
          font-weight: bold;
          margin-left: 5px;
          color: $clr-v2-soft-red;
        }
      }

      .v2-dashboard-block {
        margin-bottom: 8px;

        .lower-link {
          float: right;

          a {
            display: flex;
            width: 100%;
            text-align: right;
            padding: 0;
            color: $primary;
            transition: $transition;
            font-weight: normal;
            @include font-size(16px);

            i {
              display: inline-block;
              @include font-size(16px);
              margin-right: 5px;
              transform: translatey(2px);
            }

            &:hover {
              text-decoration: underline;
            }
          }

          &.first-lower-link {
            position: absolute;
            top: 0px;
            right: 5px;

            a {
              display: flex;
            }
          }
        }

        .close {
          position: absolute;
          width: 20px;
          height: 20px;
          white-space: nowrap;
          text-indent: 2000px;
          cursor: pointer;
          padding: 12px;
        }

        .table-block {
          min-width: 900px;
          width: 100%;
          text-align: left;
          margin-top: 10px;
          font-size: 14px;

          &.table-row-space {
            border-collapse: separate;
            border-spacing: 0 10px;
          }

          tr {
            background-color: $clr-v2-soft-grey;
            border: 1px solid $clr-v2-heavy-grey;
            border-radius: 1px;
          }

          .submenu {
            position: absolute;
            display: inline;
            top: 12px;
            margin-left: 30px;
            opacity: 0;

            &:hover {
              opacity: 1;
            }

            a {
              i {
                margin-right: 8px;
                width: 30px;
                height: 30px;
                border: 1px solid $primary-icon;
                padding: 6px;
                border-radius: 20px;
                background: $primary-icon;
                color: #fff;
              }
            }
          }

          .action {
            i {
              width: 30px;
              height: 30px;
              top: 0px;
              vertical-align: middle;

              &.icon-edit {
                width: 30px;
                height: 30px;
                border: 1px solid $primary-icon;
                padding: 8px;
                border-radius: 20px;
                background: $primary-icon;
                color: #fff;
              }

              &.icon-reading {
                position: relative;
                &::before {
                  content: '';
                  width: 28px;
                  height: 28px;
                  border-radius: 30px;
                  margin-top: 0px;
                  background: url(../img/icon-book-hover.svg) $primary-icon center center no-repeat !important;
                  position: unset;
                  display: inline-block;
                }
              }

              &.icon-editing {
                position: relative;
                &::before {
                  content: '';
                  width: 28px;
                  height: 28px;
                  border-radius: 30px;
                  margin-top: 0px;
                  background: url(../img/icon-edit-hover.svg) $primary-icon center center no-repeat !important;
                  position: unset;
                  display: inline-block;
                }
              }

              &.icon-comment {
                position: relative;
                &::before {
                  content: '';
                  width: 28px;
                  height: 28px;
                  border-radius: 30px;
                  margin-top: 0px;
                  background: url(../img/icon-comment-hover.svg) $primary-icon center center no-repeat !important;
                  position: unset;
                  display: inline-block;
                }
              }

              &.icon-published {
                position: relative;
                &::before {
                  content: '';
                  width: 28px;
                  height: 28px;
                  border-radius: 30px;
                  margin-top: 0px;
                  background: url(../img/icon-click-hover.svg) $primary-icon center center no-repeat !important;
                  position: unset;
                  display: inline-block;
                }
              }

              &.icon-checkmark {
                width: 30px;
                height: 35px;
                border: 1px solid $clr-support-yellow;
                padding: 8px;
                border-radius: 20px;
                background: $clr-support-yellow;
                color: #fff;
              }

              &.icon-search {
                width: 30px;
                height: 30px;
                border: 1px solid $search-icon;
                padding: 8px;
                border-radius: 20px;
                background: $search-icon;
                color: #fff;
              }
            }
          }

          tbody {
            tr {
              background-color: white;

              &:hover {
                td {
                  color: $primary;
                }
              }

              //Painel de Qualidade
              &.row-quality-panel {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    max-width: 600px;
                  }
                }

                &:hover {
                  .row-quality-panel-compliance {
                    position: relative;
                    margin-left: 20px;

                    .compliance {
                      width: 28px;
                      height: 28px;
                      border-radius: 30px;
                      position: relative;
                      float: right;

                      &:hover {
                        &:after {
                          opacity: 1;
                        }
                      }

                      &:before {
                        content: '';
                        width: 28px;
                        height: 28px;
                        border-radius: 30px;
                        background: url(../../../assets/img/conformidade-yes.svg) green center center no-repeat !important;
                        position: absolute;
                      }
                    }

                    .non-compliance {
                      width: 28px;
                      height: 28px;
                      border-radius: 30px;
                      position: relative;
                      float: right;
                      &:hover {
                        &:after {
                          opacity: 1;
                        }
                      }

                      &:before {
                        content: '';
                        width: 28px;
                        height: 28px;
                        border-radius: 30px;
                        background: url(../../../assets/img/conformidade.svg) $alert-darker center center no-repeat !important;
                        position: absolute;
                      }
                    }
                  }
                }
              }

              //Auditoria
              &.row-audit-management {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                }
              }

              //Documentos | Leituras pendentes
              &.row-pending-delegated-reading {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                }
              }

              &.row-pending-responsible-reading {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                }
              }

              //Documentos | Edições pendentes
              &.row-pending-delegated-edits {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                }
              }

              &.row-pending-responsible-edits {
                cursor: pointer;
                td:first-child {
                  padding-left: 18px;
                  position: relative;
                }
              }

              &.row-pending-delegated-reading,
              &.row-pending-responsible-reading,
              &.row-pending-delegated-edits,
              &.row-pending-responsible-edits {
                td span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: block;
                  width: 150px;
                }
                td:first-child {
                  span {
                    width: 250px;
                  }

                  .submenu {
                    overflow: unset;
                  }
                }
              }
              //Solicitações pendentes
              &.row-pending-requests {
                cursor: pointer;
                td:first-child .row-pending-requests-user {
                  margin-left: 18px;
                }

                .row-pending-requests-user {
                  display: inline-flex;
                  img {
                    border-radius: 20px;
                    width: 40px;
                    height: 40px;
                  }
                  span {
                    padding: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    width: 150px;
                  }
                }
              }
            }

            .block-message-row {
              .block-message {
                background-color: white;
                border: 1px solid $clr-v2-heavy-grey;
                border-radius: 1px;
                text-align: center;
                vertical-align: middle;
                width: 100%;
                cursor: auto;
                &:hover {
                  color: $clr-v2-heavier-grey;
                }
              }
            }
          }

          .block-message-hire-module {
            a {
              cursor: pointer;
              color: $primary;
              text-decoration: underline;
            }
          }

          thead {
            cursor: pointer;
            th {
              border-bottom: none;
            }
          }

          th,
          td {
            padding: 0.8em 0.1em;
            vertical-align: middle;
            color: $clr-txt-content;
          }

          .align-column {
            width: 17.4%;
          }

          .align-column-3 {
            width: 34.6%;
          }

          .align-column-2 {
            width: 32.5%;
          }

          .row-action {
            opacity: 0;
          }

          .align-column-colspan-2 {
            width: 50%;
          }

          .align-column-colspan-3 {
            width: 67.5%;
          }

          .block-row-first {
            margin-left: 8px;
            font-weight: bold;

            i {
              position: relative;
              font-size: 0.83em;
              padding-left: 7px;
              width: 20px;
              height: 14px;
              display: inline-flex;
            }
          }

          .icon-arrow-down:before {
            content: '';
            width: 20px;
            height: 14px;
            position: unset;
            display: inline-block;
            background: url(../img/icon-neutral-down.svg) transparent center center no-repeat !important;
          }

          .icon-arrow-right:before {
            content: '';
            width: 20px;
            height: 14px;
            position: unset;
            display: inline-block;
            background: url(../img/icon-neutral-right.svg) transparent center center no-repeat !important;
          }

          .visible-tbody {
            opacity: 1;
            transition: opacity 0.2s ease;
          }

          .hidden-tbody {
            * {
              border: none;
            }
            tr {
              opacity: 0;
              transition: opacity 0.2s ease;
            }
          }
        }
      }
    }
  }
}

.expired {
  color: red;
  font-weight: 500;
}
@media screen and (max-width: 1250px) {
  .v2-dashboard {
    #v2-dashboard-welcome {
      h1 {
        max-width: 320px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow-y: scroll !important;
  }
}
